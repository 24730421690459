import {
  ActivitiesRow,
  Cta,
} from '@/components';

export default {
  metaInfo() {
    return {
      title: 'Homepage',
      meta: [
        { property: 'og:title', content: 'Ikwatersport' },
        { property: 'og:site_name', content: 'Ikwatersport' },
        { property: 'og:description', content: 'Initiaties, challenges, wedstrijden en veel meer! Op een surfplank of in een zeilboot, als het maar met wind en water is. Op IKwatersport vind je het aanbod van alle Vlaamse zeil- en surf clubs.' },
        { property: 'og:image', content: 'https://ikwatersport.be/img/home.png' },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:image', content: 'https://ikwatersport.be/img/home.png' },
        { property: 'twitter:image:alt', content: 'Ikwatersport' },
        { property: 'twitter:description', content: 'Initiaties, challenges, wedstrijden en veel meer! Op een surfplank of in een zeilboot, als het maar met wind en water is. Op IKwatersport vind je het aanbod van alle Vlaamse zeil- en surf clubs.' },
      ],
    };
  },
  name: 'Home',
  components: {
    [ActivitiesRow.name]: ActivitiesRow,
    [Cta.name]: Cta,
  },
  props: {},
  data: () => ({

  }),
  computed: {},
  methods: {},
};
